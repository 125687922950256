/*
 *   File : prospect-list.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Onboarding users list ;
 *   Integrations :21-02-2024
 *   Version : v1.0
 *   Created : 21-02-2024
 */

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from 'bootstrap';

// Components
import { DownloadBtn, FilterBtn, PrimaryBtn } from "../../Components/Cta";
import { DateRangePicker, SearchInput } from "../../Components/Input";
import SelectBox from "../../Components/Dropdown/select-box";
import { EmptyNote } from "../../Components/Note";
import { DataTable } from "../../Components/DataFormating";
import { Loader } from "../../Components/Loader";
import service from "../../Services/service";
import { KYCRejectionDetailsModal } from "../../Components/Modal";

// Styles
import styles from '../../Styles/Components/search-input.module.scss';

// Images
import Sort from "../../Assets/Images/Cta/sort.svg";
import View from '../../Assets/Images/DataFormating/view.svg';
import { toast } from "react-toastify";

//Custom data
const STATUS_OPTIONS = [
    {
        id: 1,
        value: "KYC initiated",
        label: "KYC initiated",
    },
    {
        id: 1,
        value: "Customer approved",
        label: "Customer approved",
    },
    {
        id: 1,
        value: "Customer rejected",
        label: "Customer rejected",
    },
];



function ProspectList() {

    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const [refKey, setRefKay] = useState("");

    const [search, setSearch] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [clearSelected, setClearSelected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filterDates, setFilterDates] = useState({
        start: null,
        end: null,
    });

    const initialRender = useRef(true);
    const [more, setMore] = useState(false);
    const [rejectionReason, setRejectedReason] = useState([]);
    const [showRejectionDetails, setShowRejectionDetails] = useState(false);

    // TABLE DATA
    const COLUMNS = [
        {
            name: 'User ID',
            selector: row => row["if_customer_id"],
            sortable: true,
            width: "120px",

        },
        {
            name: 'Name',
            selector: row => row["name"].toLowerCase(),
            sortable: true,
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents">{row["name"]}</span>
            ),
        },
        {
            name: 'Email',
            selector: row => row["email"] || "--",
            minWidth: "200px",
            cell: row => (
                <span data-tag="allowRowEvents">{row["email"] || "--"}</span>
            ),
        },
        {
            name: 'Phone',
            selector: row => row["phone"],
            minWidth: "140px",
        },
        {
            name: 'Type',
            selector: row => row["type"] || "--",
            minWidth: "140px",
        },
        {
            name: 'Added on',
            selector: row => new Date(row["created"]).valueOf(),
            minWidth: "140px",
            sortable: true,
            cell: row => (
                <span>{row["created"]}</span>
            ),
        },
        {
            name: 'Added by',
            selector: row => new Date(row["created_by"]).valueOf(),
            minWidth: "120px",
            sortable: true,
            cell: row => (
                <span>{row.created_by === null ? "User" : "Admin"}</span>
            ),
        },
        {
            name: 'Status',
            selector: row => row["status_2"],
            width: "200px",
            cell: row => (
                row.status_2 ?
                    <div className="d-flex  w-100">
                        <span data-tag="allowRowEvents"
                            className={`e-status-badge w-auto px-2 py-1  
                        ${row.status_2 === "Customer approved" ? "e-status-badge-success" : row.status_2 === "Customer rejected" ? "e-status-badge-fail" : "e-status-badge-partially"}`}>
                            {row.status_2}
                        </span>
                        {
                            row.rejection_reason !== null &&
                            <div onMouseOver={() => { setRefKay(row.customer_id); setMore(true); }}
                                onMouseLeave={() => { setRefKay(row.customer_id); setMore(false); }}>
                                <span className="e-icon"
                                    onMouseOver={() => _openViewDetails(row.rejection_reason)}>
                                </span>
                            </div>
                        }
                    </div>
                    :
                    "--"
            )

        },
        {
            name: '',
            selector: "notify",
            width: "150px",
            cell: row => (
                row.status_1 === "NOMINEE_ADDED" || row.status_1 === "KYC_ESIGN_REQUIRED" || row.status_1 === "KYC_REJECTED" ?
                    <span className="ms-3 e-notify fw-bolder e-font-14"
                        onClick={() => _handleNotify(row.customer_id)}>
                        Notify
                    </span>
                    : ""
            ),
        },
    ];

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        setLoading(true);
        _getOnboardingUsers();
        _getFilterData();

    }, []);

    useEffect(() => {

        if (initialRender.current === false) {
            setLoading(true);
            _getOnboardingUsers();
        }

    }, [filterDates]);

    useEffect(() => {

        if (initialRender.current === false) {
            if (search === "") {
                setLoading(true);
                _getOnboardingUsers();
            }
        };

    }, [search]);

    useEffect(() => {

        if (initialRender.current === false) {
            setLoading(true);
            _getOnboardingUsers();
        };

    }, [selectedStatus]);

    useEffect(() => {

        if (showRejectionDetails === true) {
            var myModal = new Modal(document.getElementById("kyc-rejection-details"));
            myModal.show();
        };

    }, [showRejectionDetails]);

    const _openViewDetails = (rejection_reason) => {
        setRejectedReason(rejection_reason);
        setShowRejectionDetails(true);
    };

    const _handleSearchChange = (value) => {
        setSearch(value);
    };

    const _handleSearchOnEnter = (value) => {
        setLoading(true);
        _getOnboardingUsers();
    };

    const _handleDateChange = (start, end) => {
        setFilterDates({ start: start, end: end });
    };

    const _handleStatusSelect = (selected) => {
        setSelectedStatus(selected);
    };

    const _handleSelectUser = (data) => {
        setSelectedUsers(data.selectedRows);
    };

    const _handleNotify = (customer_id) => {
        _sendKYCNotification(customer_id);
    };

    const _handleDownload = () => {
        // _downloadData();
        // setToggle(true);

        // setTimeout(() => {
        //     setToggle(false);
        // }, 3000);
    };

    // API - Get Onboarding users list
    const _getOnboardingUsers = () => {

        let is_post = true;
        let url = '/kyc/onboarding-users';
        let data = {
            "query": search,
            "page_num": null,
            "page_size": null,
            "filter_id": selectedStatus.id || null,
            "from_date": filterDates.start,
            "to_date": filterDates.end
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setUsersData(response.data.users);
            } else {
                setUsersData([]);
            }
            setLoading(false);
            initialRender.current = false;
        });

    };

    // API - Get Onboarding users filter data
    const _getFilterData = () => {

        let is_post = false;
        let url = '/kyc/filter-data';
        service(is_post, url).then((response) => {
            if (response.status_code === 200) {
                let filterData = response.data.map((item) => ({
                    id: item.id,
                    label: item.name,
                    value: item.name
                }));
                setFilterData(filterData);
            } else {
                setFilterData([]);
            }
        });
    };


    // API - send KYC notification to user
    const _sendKYCNotification = (customer_id) => {
        let is_post = true;
        let url = '/kyc/notify';
        let data = {
            "customer_id": customer_id
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Notification sent to user successfully");

            } else {
                toast.dismiss();
                toast.success(response.data.message);
            };

        });
    };


    return (

        <div className="row position-relative mt-3">
            <div className="col-12">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="mb-0 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
                        Onboarding users list
                    </h3>
                    <PrimaryBtn name="Create user" handleClick={() => navigate("create-user")} />
                </div>
                <div className="bg-white min-vh-100 e-border-radius-16px overflow-hidden">
                    {/* Filter Section */}
                    <div className=" d-flex justify-content-between align-items-center p-4 pb-0">
                        <p className="e-section-title mb-0">Onboarding users list</p>
                        {/* <div className="ms-auto me-5">
                            <DownloadBtn check={selectedUsers}
                                onClick={_handleDownload} />
                        </div> */}
                        <div className="col-4 d-flex gap-3">
                            <div className="flex-fill position-relative">
                                <div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
                                    <SearchInput
                                        value={search}
                                        placeholder="Search for user name or ID"
                                        valueChange={_handleSearchChange}
                                        onSearch={_handleSearchOnEnter} />
                                    {
                                        search.length > 0 &&
                                        <>

                                            <button className={`${styles.e_search_close} pe-3`}
                                                onClick={() => {
                                                    setSearch("");
                                                }} ></button>
                                            <span className={`${styles.e_search_submit}  end-0`}
                                                onClick={() => {
                                                    setLoading(true);
                                                    _getOnboardingUsers();
                                                }}></span>
                                        </>

                                    }
                                </div>
                            </div>
                            <FilterBtn
                                className={showFilter === true ? "e-filter-btn e-active" : "e-filter-btn"}
                                handleClick={() => setShowFilter((status) => !status)} />
                        </div>
                    </div>
                    {
                        showFilter &&
                        <div className="e-bg-accordion-bg e-sort mt-3 p-4">
                            <div className="row">
                                <div className="col-auto">
                                    <label className="mb-2 e-filter-label">Added date</label>
                                    <DateRangePicker
                                        start={filterDates.start}
                                        end={filterDates.end}
                                        selectPrevious={true}
                                        onDatesChange={_handleDateChange}
                                    />
                                </div>
                                <div className="col-lg-3 col-4">
                                    <label className="mb-2 e-filter-label">Onboarding status</label>
                                    <SelectBox
                                        value={selectedStatus !== null ? selectedStatus : null}
                                        options={filterData}
                                        selectChange={_handleStatusSelect}
                                        isSearchable={false}
                                    />
                                </div>

                            </div>
                        </div>
                    }

                    {/* Users list */}
                    {
                        loading === false ? (
                            usersData.length !== 0 ? (
                                <div id="data-table"
                                    className="mt-2">
                                    <DataTable
                                        pagination={true}
                                        columns={COLUMNS}
                                        data={usersData}
                                        perPage={30}
                                        // clearSelectedRows={clearSelected ? true : false}
                                        selectableRows={true}
                                        selectRows={_handleSelectUser}
                                        rowClick={(row) => navigate(`/onboarding-users/user-detail?id=${row.customer_id}`)}
                                    // sortIcon={<image src={Sort} width="24" />}
                                    />
                                </div>
                            ) : (
                                <EmptyNote note="User list not found" />
                            )

                        ) : (
                            <div className='col-lg-12 d-flex'>
                                <Loader />
                            </div>
                        )
                    }
                </div>

            </div>
            {
                showRejectionDetails &&
                <KYCRejectionDetailsModal data={rejectionReason}
                    onModalClose={() => setShowRejectionDetails(false)} />
            }
        </div>

    );
};

export default ProspectList;
