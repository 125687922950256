/*
 *   File : add-nominee.js
 *   Author : https://evoqins.com
 *   Description : Add nominee page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { differenceInYears } from 'date-fns'
import { Modal } from 'bootstrap';

//custom component
import service from '../../../Services/service';
import { RadioGroup } from '../../../Components/Form-Elements';
import { NomineeCard } from '../../../Components/Cards';
import { PrimaryBtn } from '../../../Components/Cta';
import { toast } from 'react-toastify';
import { KycStatusModal, SendKycUrl } from '../../../Components/Modal';
import { _getSignupProgress } from '../../../Helper';
import { Loader } from '../../../Components/Loader';


const NOMINEE = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    }
];


const AddNominee = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.INDEX_FUND_STORE.GENERAL_DATA);
    const navigate = useNavigate();
    const kycURL = useRef("");

    const [generalData, setGeneralData] = useState(GENERAL_DATA);
    const [progressData, setProgressData] = useState("");
    const [customerId, setCustomerId] = useState(props.customerId);
    const [addNomineeSelect, setAddNomineeSelect] = useState(1);
    const [nomineeData, setNomineeData] = useState([
        {
            "id": 1,
            "name": null,
            "relation_id": null,
            "dob": null,
            "allocation": null,
            "guardian_name": null,
            "guardian_relationship_id": null,
            "guardian_pan": null
        }
    ]);
    const [errorMessage, setErrorMessage] = useState([
        {
            "name_error": null,
            "relation_id_error": null,
            "dob_error": null,
            "allocation_error": null,
            "guardian_name_error": null,
            "guardian_relationship_id_error": null,
            "guardian_pan_error": null
        }
    ]);

    const [fetchNominee, setFetchNominee] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showKycURLModal, setShowKycURLModal] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {

        if (progressData) {

            if (progressData.kyc_data.nominee.length !== 0) {
                setNomineeData(progressData.kyc_data.nominee);
            };

            setAddNomineeSelect(1);
        };

        setLoadingData(false);
    }, [progressData]);

    useEffect(() => {
        if (GENERAL_DATA) {
            setGeneralData(GENERAL_DATA);
        };
    }, [GENERAL_DATA]);


    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    useEffect(() => {

        if (showSuccessModal === true) {
            let kyc_status = new Modal(document.getElementById('kyc-status-modal'));
            kyc_status.show();
        }

    }, [showSuccessModal]);

    useEffect(() => {

        if (showKycURLModal === true) {
            let kyc_status = new Modal(document.getElementById('kyc-url-modal'));
            kyc_status.show();
        }

    }, [showKycURLModal]);

    //handle nominee select
    const _handleAddNominee = (id) => {
        setAddNomineeSelect(id);
    };

    //handle add more nominee
    const _handleAddMoreNominee = () => {
        if (nomineeData.length < 3) {

            const new_nominee = {
                "name": null,
                "relation_id": null,
                "dob": null,
                "allocation": null,
                "guardian_name": null,
                "guardian_relationship_id": null,
                "guardian_pan": null
            };

            const new_error = {
                "name_error": null,
                "relation_id_error": null,
                "dob_error": null,
                "allocation_error": null,
                "guardian_name_error": null,
                "guardian_relationship_id_error": null,
                "guardian_pan_error": null
            };

            // Add a new nominee to the array
            setNomineeData([
                ...nomineeData,
                {
                    ...new_nominee,
                    id: nomineeData.length + 1
                }
            ]);

            setErrorMessage([
                ...errorMessage,
                new_error
            ]);
        };
    };

    // remove nominee from list
    const _handleRemoveNominee = (key) => {
        let updated_nominee = [...nomineeData];
        updated_nominee = updated_nominee.filter((item, index) => index !== key);
        setNomineeData(updated_nominee);

        // Clearing err object releted to nominee  
        let updated_err_message = [...errorMessage];
        updated_err_message = updated_err_message.filter((item, index) => index !== key);
        setErrorMessage(updated_err_message);
    };

    //validating input field 
    const _validateNomineeData = (nominees) => {

        // Setting err array to scroll to first err input
        let error_inputs = [];

        let error_list = [...errorMessage];
        let validation_status = true;

        for (let i = 0; i < nominees.length; i++) {
            const nominee_data = nominees[i];

            const IS_MINOR = differenceInYears(new Date(), new Date(nominee_data?.dob)) < 18;

            if (nominee_data.name === null) {
                error_list[i].name_error = 'Nominee name required';
                validation_status = false;
                error_inputs.push(`name-${i}`);
            } else {
                error_list[i].name_error = null;
            }

            if (nominee_data.relation_id === null) {
                error_list[i].relation_id_error = 'Select nominee relation';
                validation_status = false;
                error_inputs.push(`relation-${i}`);
            } else {
                error_list[i].relation_id_error = '';
            }

            // nominee_data.allocation = number or empty string
            if (nominee_data.allocation === null) {
                error_list[i].allocation_error = 'Allocation is required';
                validation_status = false;
                error_inputs.push(`allocation-${i}`);
            } else {
                error_list[i].allocation_error = '';
            }

            if (nominee_data.dob === null) {
                error_list[i].dob_error = 'Nominee DOB required';
                validation_status = false;
                error_inputs.push(`dob-${i}`);
            } else {
                error_list[i].dob_error = '';
            }

            //guardian validation
            if (IS_MINOR === true) {
                if (nominee_data.guardian_name === null) {
                    error_list[i].guardian_name_error = 'Guardian Name required';
                    validation_status = false;
                    error_inputs.push(`guardian-name-${i}`);
                } else {
                    error_list[i].guardian_name_error = '';
                }

                if (nominee_data.guardian_relationship_id == null) {
                    error_list[i].guardian_relationship_id_error = 'Guardian relation is required';
                    validation_status = false;
                    error_inputs.push(`guardian-relation-${i}`);
                } else {
                    error_list[i].guardian_relationship_id_error = '';
                }

                if (nominee_data.guardian_pan === null) {
                    error_list[i].guardian_pan_error = 'PAN is required';
                    validation_status = false;
                    error_inputs.push(`guardian-pan-${i}`);
                } else {
                    error_list[i].guardian_pan_error = '';
                }
            }
        }

        setErrorMessage([...error_list]);

        // Scrolling to first error
        if (error_inputs.length !== 0) {
            let first_err_id = error_inputs[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        return validation_status;
    };

    //handle add nominee submit
    const _handleSubmitAddNominee = () => {
        // Validate UI input fields
        setLoading(true);

        if (addNomineeSelect === 2) {
            _addNominee([]);
        } else {
            setFetchNominee(true);
        };
    };

    // handle close KYC e-sign and kyc success modal
    const _handleCloseModal = () => {
        setShowKycURLModal(false);
        setShowSuccessModal(false);
        // Once KYC flow completed 
        // We can't edit data until verification completed
        // So navigate to listing page
        navigate("/onboarding-users");
    };

    //nominee data
    const _handleNomineeData = (selected_nominee, index) => {

        let current_nominees = nomineeData;

        if (selected_nominee) {
            current_nominees[index] = selected_nominee;
            setNomineeData([...current_nominees]);
        }

        if (fetchNominee === true && index === current_nominees.length - 1) {
            const is_valid = _validateNomineeData(current_nominees); // pass data through param to avoid delay in useState 

            if (is_valid) {
                _addNominee(current_nominees); // passing data through props
            } else {
                setLoading(false);
            }
            setFetchNominee(false);
        }
    };

    //API- Add nominee
    const _addNominee = (nominees) => {

        let is_post = true;
        let url = '/kyc/add-nominee';
        let data = {
            "customer_id": customerId,
            "nominee": nominees,
            "is_nominee_available": addNomineeSelect === 1 ? true : false
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                kycURL.current = response.data.redirect_url;

                toast.dismiss();

                if (addNomineeSelect == 1) {
                    toast.success("Nominee added successfully");
                } else {
                    toast.success("Nominee details updated successfully");
                };

                if (progressData.kyc_user === false) {
                    setShowKycURLModal(true);
                } else {
                    setShowSuccessModal(true);
                };

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
            setLoading(false);
        });
    };

    return (
        loadingData == false ? (
            <div className='px-0 pb-4'>
                <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                    <div className='padding-26px-top'>
                        <img src={require("../../../Assets/Images/Kyc/left-arrow.svg").default}
                            draggable={false}
                            className='cursor-pointer'
                            alt='arrow'
                            onClick={() => props.onUpdateStep(4)} />
                    </div>

                    {/* step flow */}
                    {/* step flow */}
                    <div className='position-relative'>
                        <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                        <div className='position-absolute h-6 e-bg-header-bg e-border-radius-16 top-0'
                            style={{ width: `${(100 / progressData.step_detail.length) * (progressData.kyc_data.is_kyc_compliant ? 5 : 6)}%` }}></div>
                        <p className='mb-0 text-end pt-1 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                            Step {progressData.kyc_data.is_kyc_compliant ? 5 : 6} of {progressData.step_detail.length}
                        </p>
                    </div>

                    {/* Nominee details */}
                    <div className='pt-3'>
                        <h3 className='mb-4 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                            Nominee details
                        </h3>
                        <div className='e-border-radius-16 e-bg-cornsilk p-3 mt-3 '>
                            <div className='d-flex gap-2'>
                                <img src={require("../../../Assets/Images/Kyc/hint.svg").default}
                                    draggable={false}
                                    alt='hint' />
                                <p className='mb-0 e-text-ochre e-font-14 e-font-manrope e-font-weight-400 line-height-22px letter-spacing-3' >
                                    Adding a nominee ensures your chosen person will receive your assets, simplifying the transfer process when needed
                                </p>
                            </div>
                        </div>

                        {/* add nominee */}
                        <div className='pt-4'>
                            <h6 className='mb-3 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                Do you want to add a nominee to your investment account?
                            </h6>
                            <div className='d-flex gap-3'>
                                <RadioGroup data={NOMINEE}
                                    selected={addNomineeSelect}
                                    handleSelect={_handleAddNominee} />
                            </div>
                        </div>

                        {
                            addNomineeSelect === 1 &&
                            <>
                                {/* nominee card */}
                                {nomineeData.map((data, key) => (
                                    <div className='pt-4 mt-2' key={key}>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='mb-3 e-text-chinese-black e-font-16 e-font-manrope e-font-weight-600 line-height-24px'>
                                                Nominee {key + 1}
                                            </h6>

                                            {key !== 0 && (
                                                <img src={require("../../../Assets/Images/Kyc/delete.png")}
                                                    draggable={false}
                                                    className='cursor-pointer'
                                                    alt='delete'
                                                    onClick={() => _handleRemoveNominee(key)} />
                                            )}
                                        </div>
                                        <NomineeCard
                                            data={generalData}
                                            index={key}
                                            nomineeData={data} // relation data
                                            error={errorMessage[key]}
                                            fetchNominee={fetchNominee}
                                            getNomineeData={_handleNomineeData} />
                                    </div>
                                ))}
                            </>
                        }

                        {/* custom button*/}
                        <div className='pt-4 pt-sm-5 d-flex justify-content-between align-items-center flex-wrap '>

                            <PrimaryBtn className={loading ? "e-padding-tb-12px w-40per" : "e-padding-tb-15px w-40per"}
                                loading={loading}
                                name={"Submit"}
                                handleClick={_handleSubmitAddNominee} />

                            {
                                addNomineeSelect === 1 && nomineeData.length < 3 &&
                                <p className='e-font-14-res order-1 order-sm-2 cursor-pointer mb-4 mb-sm-0 e-text-primary e-font-16 e-font-manrope e-font-weight-500 line-height-24px'
                                    onClick={_handleAddMoreNominee} >
                                    + Add new nominee
                                </p>
                            }
                        </div>
                    </div>
                </div>

                {/* For Kyc User */}
                {
                    showSuccessModal &&
                    <KycStatusModal onClose={() => setShowSuccessModal(false)} />
                }
                {/* For Non Kyc User */}
                {
                    showKycURLModal &&
                    <SendKycUrl customerId={props.customerId}
                        email={progressData.kyc_data.email}
                        phone={progressData.kyc_data.mobile_country_code + progressData.kyc_data.mobile}
                        url={kycURL.current}
                        onClose={_handleCloseModal} />
                }

            </div>
        ) : (
            <div className="min-h-100vh text-center mt-5" >
                <Loader />
            </div>
        )
    )
}

export default AddNominee;