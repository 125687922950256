/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
 */
/*import pakcages*/
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

/*import components*/
import { InvestmentWithoutGoals, Tab, } from '../../Components/DataFormating/index';
import { UserDetail } from '../../Components/Cards/index';
import { Loader } from '../../Components/Loader';
import { EmptyNote, Toast } from '../../Components/Note';
import Orders from './orders';
import { AccordionUserInvestment } from "../../Components/DataFormating";
import { AddFamilyMember, ConfirmDeleteUser } from '../../Components/Modal/index.js';


/*import container*/
import Profile from './profile.js';
import SipList from './sip-list';

/*import assets*/

/*import services*/
import service from '../../Services/service';
import { Modal } from 'bootstrap';

// Custom data 
const USER_TABS = ['Profile & KYC', 'Orders', 'Investment with goals', "Investment without goals", "SIPs"];



const UserDetails = (props) => {
    const location = useLocation()
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const addressEditAccess = useRef(false);

    const [tabIndex, setTabindex] = useState(1);
    const [id, setId] = useState(params.get("id"));
    const [detail, setDetail] = useState({});
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(null);
    const [type, setType] = useState(null);
    const [summaryLoader, setSummaryLoader] = useState(true);
    const [summaryWithoutGoalLoader, setSummaryWithoutGoalLoader] = useState(true);
    const [goalSummaryData, setGoalSummaryData] = useState([]);
    const [summaryWithoutGoal, setSummaryWithoutGoal] = useState([]);

    const [showUserDeleteConfirmModal, setShowUserDeleteConfirmModal] = useState(false);
    const [showAddFamilyMemberModal, setShowAddFamilyMemberModal] = useState(false);

    const AllocationData = [
        {
            allocation_id: 1,
        },
        {
            allocation_id: 2,
        }
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        const id = params.get("id");
        const tab = params.get("tab");
        const type = params.get("type");

        if (id) {
            setId(Number(id));
        }

        if (tab) {
            setTabindex(Number(tab));
        }
        if (type) {
            setType(type);
        }

    }, []);

    // useEffect(() => {

    //     // Updating user detail when navigating from notification list
    //     _getDetail(params.get("id"));

    // }, [params.get("id")]);

    // Calling api only once by checking detail data
    useEffect(() => {

        const id = params.get("id");

        if (tabIndex == 1 && detail["overview"] == null) {
            _getDetail(id);
        } else if (tabIndex == 3 && goalSummaryData.length == 0) {
            setSummaryLoader(true);
            _getGoalSummary(id);
        } else if (tabIndex == 4 && summaryWithoutGoal.length == 0) {
            setSummaryWithoutGoalLoader(true);
            _getInvestmentSummaryWithoutGoals(id);
        } else if (tabIndex == 2 && detail["overview"] == null) {
            _getDetail(id);
        }

    }, [tabIndex]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error]);

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get('tab');
        const id = searchParams.get('id');

        if (tab > 5) {
            setTabindex(1);
        }

        // if (id) {
        //     _getDetail(id);
        // }

    }, [location]);

    useEffect(() => {

        if (showUserDeleteConfirmModal) {
            let modal = new Modal(document.getElementById("confirm-user-delete-modal"));
            modal.show();
        };

    }, [showUserDeleteConfirmModal]);

    useEffect(() => {

        if (showAddFamilyMemberModal) {
            let modal = new Modal(document.getElementById("add-family-member-modal"));
            modal.show();
        };

    }, [showAddFamilyMemberModal]);


    // const handle open user delete modal
    const _handleShowUserDeleteConfirmModal = () => {
        setShowUserDeleteConfirmModal(true);
    };

    // const handle open user delete modal
    const _handleShowAddFamilyMemberModal = () => {
        setShowAddFamilyMemberModal(true);
    };

    // handle user delete success
    const _handleUserDeleteSuccess = () => {
        navigate("/users");
    };


    /* ----- get user's detail api -----*/
    const _getDetail = () => {

        const id = params.get("id");

        setLoader(true);
        const url = '/user/detail';
        const data = {
            "user_id": parseInt(id)
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data);
                addressEditAccess.current = response.data.kyc.status === "INVESTED" || response.data.kyc.status === "ACCOUNT_CREATED" || response.data.overview.kyc_complaint == true;
            }
            else {
                setDetail({})
            }
            setLoader(false)
        })
    };

    /*----- get goal summary ----*/
    const _getGoalSummary = (temp) => {


        const url = '/user/goal-summary';
        const data = {
            "customer_id": parseInt(temp)
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setGoalSummaryData(response.data);
            } else {
                setGoalSummaryData([]);
            }
            setSummaryLoader(false);
        });

    };

    /*----- get investment summary without goals ----*/
    const _getInvestmentSummaryWithoutGoals = (temp) => {

        const url = '/user/investment-without-goals';
        const data = {
            "user_id": parseInt(temp)
        }

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSummaryWithoutGoal(response.data);
            } else {
                setSummaryWithoutGoal([]);
            }

            setSummaryWithoutGoalLoader(false);
        });

    };

    return (
        <>
            {
                loader ?
                    <div className='col-lg-12 '>
                        <Loader />
                    </div>

                    :
                    Object.values(detail).length > 0 ?
                        <>
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    {
                                        type === 2 ?
                                            <span className="e-breadcrumb-link"><Link to="/analytics ">Analytics</Link>/{detail?.overview?.name}</span>
                                            :
                                            <span className="e-breadcrumb-link"><span onClick={() => {
                                                sessionStorage.removeItem("invested_status");
                                                sessionStorage.removeItem("range");
                                                sessionStorage.removeItem("user_search");
                                                sessionStorage.removeItem("order_status");
                                                sessionStorage.removeItem("payment_status");
                                                sessionStorage.removeItem("type");
                                                sessionStorage.removeItem("order_range");
                                                sessionStorage.removeItem("order_search");

                                            }}><Link to="/users ">Users</Link></span>/ {detail?.overview?.name}</span>
                                    }

                                </div>
                            </div>
                            <div className="row">

                                {/*-----------card displaying basic details------------------*/}
                                <div className="col-lg-12">
                                    <UserDetail
                                        data={detail}
                                        userId={id}
                                        handleAddFamilyMember={_handleShowAddFamilyMemberModal}
                                        handleDeleteUser={_handleShowUserDeleteConfirmModal}
                                    />
                                </div>

                                {/*----------- tabs ------------------*/}
                                <div className="col-lg-12 mt-4 mb-4">
                                    <Tab
                                        // data={['Profile & KYC', 'Orders', 'Holdings', 'Goal Investments', 'Non Goal Investments', 'SIPs']}
                                        data={USER_TABS}
                                        setTab={(temp) => setTabindex(temp)}
                                        currentIndex={tabIndex}
                                    />
                                </div>
                                {
                                    tabIndex === 1 ?
                                        <Profile
                                            data={detail}
                                            userId={id}
                                            editAccess={addressEditAccess.current}
                                            // editUserDetails={() => _getDetail()}
                                            handleEditSuccess={() => _getDetail()}
                                        />
                                        : tabIndex === 2 ?
                                            <Orders
                                                fName={detail.overview && detail.overview.name ? detail.overview.name : "--"}
                                                id={id} />
                                            :
                                            // tabIndex === 3 ?
                                            //     <Holdings
                                            //         id={id}
                                            //     />
                                            //     :
                                            tabIndex === 3 ?
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card e-dashboard-card border-0 p-4 position-relative">
                                                            {
                                                                summaryLoader ?
                                                                    <Loader />
                                                                    :
                                                                    goalSummaryData.length > 0 ?
                                                                        <AccordionUserInvestment
                                                                            data={AllocationData}
                                                                            summaryData={goalSummaryData} />
                                                                        :
                                                                        <EmptyNote note="No data found" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                tabIndex === 4 ?
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="card e-dashboard-card border-0 p-4 position-relative">
                                                                {
                                                                    summaryWithoutGoalLoader ?
                                                                        <Loader />
                                                                        :
                                                                        <InvestmentWithoutGoals
                                                                            data={AllocationData}
                                                                            summaryData={summaryWithoutGoal} />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <SipList
                                                                id={id} />
                                                        </div>
                                                    </div>
                                }

                            </div>

                            {/* Toast error */}
                            {
                                error !== null ?
                                    <Toast data={error}
                                        type="error"
                                        id="1" />
                                    :
                                    null
                            }
                        </>
                        :
                        <div className='e-dashboard-card card pb-5 mb-5'>
                            <EmptyNote note="Customer details not found" />
                        </div>
            }

            {
                showUserDeleteConfirmModal &&
                <ConfirmDeleteUser handleSuccess={_handleUserDeleteSuccess}
                    userId={id}
                    onCloseModal={() => setShowUserDeleteConfirmModal(false)} />
            }
            {
                showAddFamilyMemberModal &&
                <AddFamilyMember type={2}
                    customerId={id}
                    onSuccess={() => setShowAddFamilyMemberModal(false)}
                    onCloseModal={() => setShowAddFamilyMemberModal(false)} />
            }
        </>
    );
}

export default UserDetails;
