/*
 *   File : edit-bank.js
 *   Author : https://evoqins.com
 *   Description : Modal component to edit bank details of user
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

// Components
import { CustomTextInput, RadioGroup } from "../Form-Elements";
import { PrimaryBtn, SecondaryBtn } from "../Cta";
import service from "../../Services/service";
import SelectionSuggestModal from "./select-suggestion";
import { _closeModal } from "../../Helper/function";

function EditBankDetailsModal(props) {

  const GENERAL_DATA = useSelector((store) => store.INDEX_FUND_STORE.GENERAL_DATA);
  let account_type = GENERAL_DATA.bank_account_types.find(item => item.display_name == props.data.bank.account_type) || "";
  account_type = account_type ? account_type : GENERAL_DATA.bank_account_types[0];

  const [accountNumber, setAccountNumber] = useState(props.data.bank.account_number || "");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [holderName, setHolderName] = useState(props.data.bank.account_holder || "");
  const [holderNameError, setHolderNameError] = useState("");
  const [ifsc, setIfsc] = useState(props.data.bank.ifsc || "");
  const [ifscError, setIfscError] = useState("");
  const [accountType, setAccountType] = useState(account_type.id);

  const [selectedBank, setSelectedBank] = useState({ name: props.data.bank.name });
  const [selectedBranch, setSelectedBranch] = useState({ name: props.data.bank.branch });
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [branchListLoading, setBranchListLoading] = useState(true);
  const [showBankSelectModal, setShowBankSelectModal] = useState(false);
  const [showBranchSelectModal, setShowBranchSelectModal] = useState(false);


  useEffect(() => {
    // API call - get bank list
    _getBankList();
  }, []);

  // Closing modal when "ESC" pressed
  useEffect(() => {
    _closeModal(props.onCloseModal);
  }, []);

  // Open bank select modal
  useEffect(() => {
    if (showBankSelectModal) {
      let suggestion_modal = new Modal(document.getElementById('select-bank-name-modal'));
      suggestion_modal.show();
    }
  }, [showBankSelectModal]);


  // Open bank branch select modal
  useEffect(() => {
    if (showBranchSelectModal) {
      let suggestion_modal = new Modal(document.getElementById('select-bank-branch-modal'));
      suggestion_modal.show();
    }
  }, [showBranchSelectModal]);

  // handle account number change
  const _handleAccountNumberChange = (value) => {
    if (value.length <= 18) {
      setAccountNumber(value);
      setAccountNumberError();
    };
  };

  // handle holder name change
  const _handleAccountHolderNameChange = (value) => {
    setHolderName(value);
    setHolderNameError("");
  };

  // handle change IFSC code
  const _handleChangeIFSCCode = (value) => {
    setIfsc(value.toUpperCase());
  };

  //handle account type
  const _handleAccountType = (id) => {
    setAccountType(id)
  };

  // handle bank select
  const _handleBankSelect = (selected_bank) => {
    setSelectedBank(selected_bank);
    setShowBankSelectModal(false);
    setBranchList([]);
    setBranchListLoading(true);
    setShowBranchSelectModal(true);
    // API call
    _getBranchList(selected_bank);
  };

  // handle branch select
  const _handleBranchSelect = (value) => {
    setSelectedBranch(value);
    setIfsc(value.ifsc);
    setShowBranchSelectModal(false);
  };

  // handle open branch select modal
  const _handleOpenBankSelectModal = () => {
    setShowBankSelectModal(true);
  };

  // handle validate data
  const _handleValidateData = () => {

    let validation = true;

    if (accountNumber.length === 0) {
      setAccountNumberError("Enter account number");
      validation = false;
    }

    if (holderName.length === 0) {
      setHolderNameError("Account holder name required");
      validation = false;
    }

    if (ifsc.length == 0) {
      setIfscError("Please enter the IFSC code");
      validation = false;
    }

    if (validation == true) {
      setLoading(true);
      // API- call
      _updateBankDetails();
    }
  };

  // API - Search bank
  const _getBankList = () => {
    let is_post = true;
    let url = '/general/banks';
    let data = {
      "query": null
    };

    service(is_post, url, data).then((response) => {
      if (response.status_code === 200) {

        // Adding label prop for search options 
        let search_results = response.data.map(item => {
          return {
            ...item,
            id: item.id,
            label: item.name,
            value: item.name
          }
        });

        setBankList(search_results);
        setBranchList([]);
      } else {
        setBankList([]);
        setBranchList([]);
      }
    });

  };

  // API - Search branch 
  const _getBranchList = (selected_bank) => {
    let is_post = true;
    let url = '/general/branches';
    let data = {
      "bank_id": selected_bank.id,
      "query": ""
    };

    service(is_post, url, data).then((response) => {
      if (response.status_code === 200) {

        // Adding label prop for search options 
        let search_results = response.data.map(item => {
          return {
            ...item,
            label: item.name,
            id: item.name,
            value: item.name
          }
        });
        setBranchList(search_results);

      } else {
        setBranchList([]);
      }

      setBranchListLoading(false);

    });

  };

  // API - Update basic data 
  const _updateBankDetails = () => {
    let is_post = true;
    let url = '/user/update-bank';
    let data = {
      "account_number": accountNumber,
      "account_holder_name": holderName,
      "account_type": accountType,
      "ifsc": ifsc,
      "customer_id": props.userId,
      "branch_name": selectedBranch.name,
      "bank_name": selectedBank.name
    };

    service(is_post, url, data).then((response) => {
      if (response.status_code === 200) {

        toast.dismiss();
        toast.success("Bank details updated successfully");
        let close_btn = document.getElementById("edit-bank-detail-modal-close");
        close_btn.click();
        props.handleEditSuccess();


      } else {
        toast.dismiss();
        toast.error(response.data.message);
      }

      setLoading(false);
    });
  };

  return (
    <div className={`modal fade e-manage-modal`}
      id="edit-bank-detail-modal"
      data-bs-backdrop="static"
      tabIndex="-1"
      aria-labelledby="EditBasicModalLabel"
      aria-hidden="true">
      <div className={`modal-dialog  modal-dialog-centered modal-dialog-scrollable`}>
        <div className="modal-content e-border-radius-8 p-0 h-90vh" >
          <div className="modal-header d-flex justify-content-between pt-3 px-4 pb-2 border-0">
            <h6 className='modal-title mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
              Edit bank details
            </h6>
            <button type="button"
              id="edit-bank-detail-modal-close"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onCloseModal}></button>
          </div>
          <div className="modal-body pb-0 px-4 pt-0">

            {/* Account number */}
            <div id="account-number" className='h-90 mb-2'>
              <CustomTextInput label="Account number"
                require
                placeholder="99980107428"
                maxLength={18}
                value={accountNumber}
                errorMsg={accountNumberError}
                handleChange={_handleAccountNumberChange} />
            </div>

            {/* Account holder name */}
            <div id="account-holder" className='h-90 mb-2'>
              <CustomTextInput label="Account holder name"
                require
                placeholder="Jhon Doe"
                type="text"
                value={holderName}
                errorMsg={holderNameError}
                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                labelStyle="e-font-weight-500"
                handleChange={_handleAccountHolderNameChange} />
            </div>

            {/* Account type select */}
            <div className='pb-3'>
              <label className='mb-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                Account type
                <span className='e-text-red'>*</span>
              </label>
              <div className='d-flex gap-2 flex-wrap'>
                <RadioGroup data={GENERAL_DATA.bank_account_types}
                  selected={accountType}
                  handleSelect={_handleAccountType} />
              </div>
            </div>

            {/* IFSC code */}
            <div id="ifsc-code" className='h-90 position-relative'>
              <CustomTextInput label="IFSC code"
                require
                placeholder="SBIN0015895"
                type="text"
                value={ifsc}
                errorMsg={ifscError}
                className="w-100 px-3 padding-12px-top padding-12px-bottom"
                labelStyle="e-font-weight-500"
                handleChange={_handleChangeIFSCCode} />
              <span className="text-decoration-hover-underline link-offset-1 cursor-pointer position-absolute top-90 end-0 e-text-primary e-font-manrope e-font-weight-500  e-font-14"
                onClick={_handleOpenBankSelectModal}>
                Find IFSC Code
              </span>
            </div>
          </div>
          <div className="modal-footer pb-4 pt-3 border-0">
            {/* Action buttons */}
            <div className='row justify-content-end w-100'>
              <div className='col-4'>
                <SecondaryBtn name="Cancel"
                  className="w-100 h-40 d-flex justify-content-center align-items-center"
                  cancel="modal"
                  handleClick={props.onCloseModal} />
              </div>
              <div className='col-4'>
                <PrimaryBtn name="Update"
                  className="w-100 h-40 d-flex justify-content-center align-items-center"
                  loading={loading}
                  handleClick={_handleValidateData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bank search and select modal */}
      {
        showBankSelectModal && bankList.length > 0 &&
        <SelectionSuggestModal title="Select bank"
          type={1}
          search={true}
          searchPlaceholder="Search bank"
          selectedOption={selectedBank}
          data={bankList}
          backdrop={"false"}
          id='select-bank-name-modal'
          onModalClose={() => setShowBankSelectModal(false)}
          onSelectOption={_handleBankSelect} />
      }

      {/* Bank branch select modal */}
      {
        showBranchSelectModal &&
        <SelectionSuggestModal title="Select branch"
          type={2}
          search={true}
          searchPlaceholder="Search branch"
          selectedOption={selectedBranch}
          data={branchList}
          backdrop={"false"}
          loading={branchListLoading}
          id='select-bank-branch-modal'
          onModalClose={() => setShowBranchSelectModal(false)}
          onSelectOption={_handleBranchSelect} />
      }

    </div>
  )
};

export default EditBankDetailsModal;