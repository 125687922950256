/*
 *   File : prospect-detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Onboarding user detail page ;
 *   Integrations :21-02-2024
 *   Version : v1.0
 *   Created : 21-02-2024
 */

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import { Tab } from "../../Components/DataFormating";
import { PrimaryBtn } from "../../Components/Cta";
import { Loader } from "../../Components/Loader";
import { _getSignupProgress } from "../../Helper";
import service from "../../Services/service";
import { EmptyNote } from "../../Components/Note";

// Assets
import Edit from '../../Assets/Images/Cta/edit.svg';
import PNGFileIcon from '../../Assets/Images/General/img-file-icon.png';
import EditProfileLog from "./edit-profile-log";

//Custom data
const TAB = ["User detail", "Edit log"];

function ProspectDetail() {

    const navigate = useNavigate();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const customerId = useRef(search.get("id"))
    const current_step = useRef(0);
    const userName = useRef("");

    const [userData, setUserData] = useState([]);
    const [tabIndex, setTabindex] = useState(1);
    const [loading, setLoading] = useState(true);
    const [notifyLoader, setNotifyLoader] = useState(false);

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        setLoading(true);
        _getUserData();

    }, []);

    const _handleNotify = () => {
        setNotifyLoader(true);
        _sendKYCNotification();
    };

    const _handleEdit = () => {
        let customer_id = search.get("id");
        navigate("/onboarding-users/create-user", {
            state: {
                customer_id
            }
        });
    };


    // API - Get user data
    const _getUserData = () => {
        _getSignupProgress(customerId.current).then(response => {
            if (response.status_code === 200) {
                setUserData(response.data);
                current_step.current = response.data.current_step;
                let user_name = response.data.kyc_data.name.split(" ").map(name => name.at(0)).join("");
                userName.current = user_name.toUpperCase();

            } else {
                navigate(-1);
            }
            setLoading(false);
        });

    };

    // API - send KYC notification to user
    const _sendKYCNotification = () => {
        let is_post = true;
        let url = '/kyc/notify';
        let data = {
            "customer_id": customerId.current
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Notification sent to the user successfully");
            } else {
                toast.dismiss();
                toast.success(response.data.message);
            };

            setNotifyLoader(false);

        });
    };


    return (
        <>


            <div className="row position-relative">
                {
                    loading === false &&

                    <div className="col-12">
                        <span className="e-font-manrope e-font-14">
                            <span className="p-0 e-text-white cursor-pointer " onClick={() => navigate("/onboarding-users")}>Onboarding users</span>
                            <span className="e-text-tab-color"> / {userData.kyc_data.name}</span>
                        </span>

                        {/* User banner */}
                        <div className="d-flex align-items-center gap-3 bg-white mt-3 mb-4 e-border-radius-16 p-4">
                            <div className="col-1 h-100px width-100px d-flex align-items-center justify-content-center e-bg-soap e-border-radius-50per">
                                <span className="mx-1 e-text-primary e-font-manrope e-font-weight-600 e-font-32 px-2">
                                    {userName.current}
                                </span>
                            </div>
                            <div className="col-3 col-md-2 col-xl-3 d-flex gap-2 flex-column">
                                <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-24">
                                    {userData.kyc_data.name}
                                </span>
                            </div>
                            <div className="col-8 col-md-9 col-xl-8 flex-fill d-flex flex-wrap row-gap-3 justify-content-between e-bg-ghost-white  e-border-radius-8 p-3">
                                <div className="col-12 col-md-6 d-flex gap-2">
                                    <span className="e-text-light-grey e-font-manrope e-font-weight-500 e-font-14">
                                        Email:
                                    </span>
                                    <span className="text-wrap e-text-black e-font-manrope e-font-weight-600 e-font-14">
                                        <a style={{ color: "currentColor" }}
                                            href={`mailto:${userData.kyc_data.email}`}>
                                            {
                                                userData.kyc_data.email ?
                                                    userData.kyc_data.email.toLowerCase()
                                                    :
                                                    "--"
                                            }
                                        </a>
                                    </span>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-md-end gap-2 mb-3">
                                    <span className="e-text-light-grey e-font-manrope e-font-weight-500 e-font-14">
                                        Phone no:
                                    </span>
                                    <span className="e-text-black e-font-manrope e-font-weight-600 e-font-14">
                                        <a href={`tel:${userData.kyc_data.mobile}`}
                                            style={{ color: "currentColor" }}>
                                            {
                                                userData.kyc_data.mobile ?
                                                    userData.kyc_data.mobile_country_code + "-" + userData.kyc_data.mobile
                                                    :
                                                    "--"
                                            }
                                        </a>
                                    </span>
                                </div>
                                {
                                    userData.kyc_data.status_2 &&
                                    <div className="col-6 d-flex gap-3 align-self-end">
                                        <span className={`${userData.kyc_data.status_2 === "Customer approved" ? "e-status-badge-success" : userData.kyc_data.status_2 === "Customer rejected" ? "e-status-badge-fail" : "e-status-badge-partially"} px-3 py-2 align-self-start e-text-white e-font-manrope e-font-weight-600 e-font-12 e-status-badge w-auto`}>
                                            {userData.kyc_data.status_2}
                                        </span>
                                    </div>
                                }
                                <div className="ms-auto col-6 d-flex justify-content-end align-items-center gap-3">
                                    <p className={`${(userData.current_step > 5 && userData.current_step !== 8) && "e-disable"} d-flex gap-2 align-items-center cursor-pointer e-font-manrope fw-bold e-font-14 mb-0 line-height-0`}
                                        onClick={_handleEdit}>
                                        Edit
                                        <img src={Edit}
                                            width={14}
                                            height={12}
                                            alt="edit"
                                        />
                                    </p>
                                    {
                                        userData.kyc_data.status_1 === "NOMINEE_ADDED" || userData.kyc_data.status_1 === "KYC_ESIGN_REQUIRED" || userData.kyc_data.status_1 === "KYC_REJECTED" ?
                                            <PrimaryBtn name="Notify"
                                                className="h-40 col-auto col-lg-5 col-xl-4 d-flex justify-content-center"
                                                loading={notifyLoader}
                                                disabled={notifyLoader}
                                                handleClick={_handleNotify} />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>

                        {/* User detail tabs */}
                        <div className="my-0">
                            <Tab
                                data={TAB}
                                setTab={(tab_index) => setTabindex(tab_index)}
                                currentIndex={tabIndex}
                            />
                        </div>

                        {
                            tabIndex === 1 &&
                            // User Details
                            <>

                                <div className="d-flex flex-column gap-26px mt-4">
                                    {/* Personal details */}
                                    {
                                        current_step.current <= 1 ? (
                                            <div div className="bg-white e-border-radius-16 p-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14">
                                                        Personal details
                                                    </span>

                                                </div>
                                                <div className="d-flex flex-column gap-4 e-bg-ghost-white e-border-radius-8 p-3 mt-3">
                                                    <div className="row row-cols-4 row-gap-3">
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Full name
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.name}
                                                            </span>
                                                        </div>

                                                        {
                                                            userData.kyc_data.pan &&
                                                            <div className="col d-flex flex-column gap-1">
                                                                <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                    PAN
                                                                </span>
                                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                    {userData.kyc_data.pan.toUpperCase()}
                                                                </span>
                                                            </div>
                                                        }
                                                        {
                                                            userData.kyc_data.is_email_verified &&
                                                            <div className="col d-flex flex-column gap-1">
                                                                <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                    Email
                                                                </span>
                                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                    <a style={{ color: "currentColor" }}
                                                                        href={`mailto:${userData.kyc_data.email}`}>
                                                                        {
                                                                            userData.kyc_data.email ?
                                                                                userData.kyc_data.email.toLowerCase()
                                                                                :
                                                                                "--"
                                                                        }
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        }
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Email verified
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.is_email_verified ? "Yes" : "No"}
                                                            </span>
                                                        </div>
                                                        {
                                                            userData.kyc_data.mobile &&
                                                            <div className="col d-flex flex-column gap-1">
                                                                <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                    Phone no
                                                                </span>
                                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                    <a href={`tel:${userData.kyc_data.mobile}`}
                                                                        style={{ color: "currentColor" }}>
                                                                        {
                                                                            userData.kyc_data.mobile ?
                                                                                userData.kyc_data.mobile_country_code + "-" + userData.kyc_data.mobile
                                                                                :
                                                                                "--"
                                                                        }
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            current_step.current >= 2 &&
                                            <div className="bg-white e-border-radius-16 p-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14">
                                                        Personal details
                                                    </span>
                                                </div>

                                                <div className="d-flex flex-column gap-4 e-bg-ghost-white e-border-radius-8 p-3 mt-3">
                                                    <div className="row row-cols-3 row-gap-3">
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Full name
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.name}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Date of birth
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.dob}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Email
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                <a style={{ color: "currentColor" }}
                                                                    href={`mailto:${userData.kyc_data.email}`}>
                                                                    {
                                                                        userData.kyc_data.email ?
                                                                            userData.kyc_data.email.toLowerCase()
                                                                            :
                                                                            "--"
                                                                    }
                                                                </a>
                                                            </span>
                                                        </div>

                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Phone no
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                <a href={`tel:${userData.kyc_data.mobile}`}
                                                                    style={{ color: "currentColor" }}>
                                                                    {
                                                                        userData.kyc_data.mobile ?
                                                                            userData.kyc_data.mobile_country_code + "-" + userData.kyc_data.mobile
                                                                            :
                                                                            "--"
                                                                    }
                                                                </a>
                                                            </span>
                                                        </div>
                                                        {
                                                            userData.kyc_user === false && userData.kyc_data.aadhaar_card_number &&
                                                            <div className="col d-flex flex-column gap-1">
                                                                <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                    Aadhaar no (last 4 digits)
                                                                </span>
                                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                    {userData.kyc_data.aadhaar_card_number}
                                                                </span>
                                                            </div>
                                                        }
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Gender
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.gender}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Marital status
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.marital_status}
                                                            </span>
                                                        </div>
                                                        {
                                                            userData.kyc_user === false &&
                                                            <>
                                                                <div className="col d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Father name
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {userData.kyc_data.father_name}
                                                                    </span>
                                                                </div>
                                                                <div className="col d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Mother name
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {userData.kyc_data.mother_name}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Citizenship
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.country}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                PAN
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.pan.toUpperCase()}
                                                            </span>
                                                        </div>

                                                        {
                                                            userData.kyc_data.is_indian_tax_payer === false &&
                                                            <>
                                                                <div className="col d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Tax country
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {userData.kyc_data.tax_country}
                                                                    </span>
                                                                </div>
                                                                <div className="col d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Tax reference no
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {userData.kyc_data.tax_reference_number}
                                                                    </span>
                                                                </div>
                                                                <div className="col d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Tax reference proof
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {userData.kyc_data.reference_proof}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }

                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Occupation
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.occupation}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Source of income
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.income_source}
                                                            </span>
                                                        </div>
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Annual income
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.anual_income.toLocaleString()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {
                                                        userData.kyc_user === false &&
                                                        <div className="d-flex flex-column gap-2">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Uploaded documents
                                                            </span>
                                                            <div className="row gap-3 flex-wrap">
                                                                <div className="col-auto d-flex align-items-center gap-2">
                                                                    <a href={userData.kyc_data.identity_proof_url}
                                                                        target='_blank'
                                                                        className='border p-2 e-border-radius-8 e-border-chinese-white' >
                                                                        <img src={PNGFileIcon} width={24} height={24} alt="file icon" />
                                                                        <span className="ps-2 e-text-chinese-black e-font-manrope e-font-weight-500 e-font-14">
                                                                            {userData.kyc_data.identity_proof_url.split("?")[0].split("/").at(-1)}
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-auto d-flex align-items-center gap-2">
                                                                    <a href={userData.kyc_data.photo_proof_url}
                                                                        target='_blank'
                                                                        className='border p-2 e-border-radius-8 e-border-chinese-white' >
                                                                        <img src={PNGFileIcon} width={24} height={24} alt="file icon" />
                                                                        <span className="ps-2 e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                            {userData.kyc_data.photo_proof_url.split("?")[0].split("/").at(-1)}
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-auto d-flex align-items-center gap-2">
                                                                    <a href={userData.kyc_data.signature_proof_url}
                                                                        target='_blank'
                                                                        className='border p-2 e-border-radius-8 e-border-chinese-white' >
                                                                        <img src={PNGFileIcon} width={24} height={24} alt="file icon" />
                                                                        <span className="ps-2 e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                            {userData.kyc_data.signature_proof_url.split("?")[0].split("/").at(-1)}
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )

                                    }

                                    {/* Address details */}
                                    {
                                        current_step.current >= 4 &&
                                        <div className="bg-white e-border-radius-16 p-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14">
                                                    Address details
                                                </span>

                                            </div>

                                            <div className="d-flex flex-column gap-4 e-bg-ghost-white e-border-radius-8 p-3 mt-3">
                                                <div className="row row-cols-3 row-gap-3">
                                                    <div className="col d-flex flex-column gap-1">
                                                        <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                            Permanent address
                                                        </span>
                                                        <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                            {userData.kyc_data.address.permanent_address.address_line1}, <br />
                                                            {
                                                                userData.created_by == null &&
                                                                <>
                                                                    {userData.kyc_data.address.permanent_address.address_line2}, <br />
                                                                </>

                                                            }
                                                            {userData.kyc_data.address.permanent_address.city}, <br />
                                                            {userData.kyc_data.address.permanent_address.state} - {" "}
                                                            {userData.kyc_data.address.permanent_address.pincode}

                                                        </span>
                                                    </div>
                                                    {
                                                        userData.kyc_data.is_same_corresponding_address === false &&
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Overseas address
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                {userData.kyc_data.address.corresponding_address.address_line1}, <br />
                                                                {userData.kyc_data.address.corresponding_address.address_line2}, <br />
                                                                {userData.kyc_data.address.corresponding_address.city}, <br />
                                                                {userData.kyc_data.address.corresponding_address.state} - {" "}
                                                                {userData.kyc_data.address.corresponding_address.pincode}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Bank details */}
                                    {
                                        current_step.current >= 5 &&
                                        <div className="bg-white e-border-radius-16 p-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14">
                                                    Bank details
                                                </span>
                                            </div>

                                            <div className="d-flex flex-column gap-4 e-bg-ghost-white e-border-radius-8 p-3 mt-3">
                                                <div className="row row-cols-3 row-gap-3">
                                                    <div className="col d-flex flex-column gap-1">
                                                        <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                            Account holder name
                                                        </span>
                                                        <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                            {userData.kyc_data.bank_details.account_holder_name}
                                                        </span>
                                                    </div>
                                                    <div className="col d-flex flex-column gap-1">
                                                        <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                            Account number
                                                        </span>
                                                        <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                            {userData.kyc_data.bank_details.account_number}
                                                        </span>
                                                    </div>
                                                    <div className="col d-flex flex-column gap-1">
                                                        <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                            IFSC code
                                                        </span>
                                                        <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                            {userData.kyc_data.bank_details.ifsc}
                                                        </span>
                                                    </div>
                                                    <div className="col d-flex flex-column gap-1">
                                                        <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                            Account type
                                                        </span>
                                                        <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                            {userData.kyc_data.bank_details.account_type}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Nominee details */}
                                    {
                                        current_step.current >= 6 &&
                                        <div className="bg-white e-border-radius-16 p-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14">
                                                    Nominee details
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column gap-4 e-bg-ghost-white e-border-radius-8 p-3 mt-3">
                                                {
                                                    userData.kyc_data.is_nominee_available === true ? (
                                                        userData.kyc_data.nominee.map((nominee, key) => (
                                                            <div className="row row-gap-3" key={key}>
                                                                {
                                                                    userData.kyc_data.nominee.length > 1 &&
                                                                    <h5 className={`e-text-chinese-black e-font-manrope e-font-weight-700  e-font-14 mb-0`}>
                                                                        Nominee {key + 1}
                                                                    </h5>
                                                                }
                                                                <div className="col-4 d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Nominee name
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {nominee.name}
                                                                    </span>
                                                                </div>
                                                                <div className="col-4 d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Date of birth
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {nominee.dob}
                                                                    </span>
                                                                </div>
                                                                <div className="col-4 d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Relation
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {nominee.relation}
                                                                    </span>
                                                                </div>
                                                                <div className="col-4 d-flex flex-column gap-1">
                                                                    <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                        Allocation
                                                                    </span>
                                                                    <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                        {nominee.allocation}
                                                                    </span>
                                                                </div>
                                                                {
                                                                    nominee.guardian_name &&
                                                                    <>
                                                                        <div className="col-4 d-flex flex-column gap-1">
                                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                                Guardian name
                                                                            </span>
                                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                                {nominee.guardian_name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4 d-flex flex-column gap-1">
                                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                                Guardian pan
                                                                            </span>
                                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                                {nominee.guardian_pan}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4 d-flex flex-column gap-1">
                                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                                Guardian relationship
                                                                            </span>
                                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                                {nominee.guardian_relationship}
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        ))

                                                    ) : (
                                                        <div className="col d-flex flex-column gap-1">
                                                            <span className="e-text-light-grey e-font-manrope e-font-weight-600  e-font-14">
                                                                Nominee Opt-In
                                                            </span>
                                                            <span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
                                                                No
                                                            </span>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    current_step.current === 0 &&
                                    <EmptyNote note={"There is no data to show"} />
                                }
                            </>

                        }
                        {
                            tabIndex === 2 &&
                            <EditProfileLog />

                        }
                    </div>
                }
                {
                    loading === true &&
                    <Loader />
                }
            </div>
        </>
    );
};

export default ProspectDetail;